/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { MQ } from '../core/constants/styles';

export const IndexContainer: React.FunctionComponent = (props) => {
    const { children } = props;
    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                ${MQ({ paddingLeft: [30, 0, 0, 0], paddingRight: [30, 0, 0, 0] })}
            `}
        >
            {children}
        </div>
    );
};
