/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { MQ } from '../core/constants/styles';

export const IndexInnerContainer: React.FunctionComponent = (props) => {
    const { children } = props;
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                ${MQ({ width: ['100%', 560, 850, 1140] })}
            `}
        >
            {children}
        </div>
    );
};
