/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { MQ } from '../core/constants/styles';
import { TopText } from './TopText';
import { InputBox } from './InputBox';

export const Top: React.FunctionComponent = () => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
                ${MQ({
                    marginTop: [60, 123, 123, 123],
                    marginBottom: [25, 48, 54, 54],
                    flexDirection: ['column', 'column', 'column', 'row'],
                })}
            `}
        >
            <TopText />
            <InputBox />
        </div>
    );
};
