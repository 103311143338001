/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostType = "CHEER" | "MEMORY" | "THANKS" | "%future added value";
export type Card_post = {
    readonly id: string;
    readonly author: string;
    readonly type: PostType;
    readonly content: string;
    readonly createdAt: unknown;
    readonly " $fragmentRefs": FragmentRefs<"Comment_post">;
    readonly " $refType": "Card_post";
};
export type Card_post$data = Card_post;
export type Card_post$key = {
    readonly " $data"?: Card_post$data;
    readonly " $fragmentRefs": FragmentRefs<"Card_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Card_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "author",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Comment_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = '2541276881ca328776f8485457056eef';
export default node;
