/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
/** @jsxImportSource @emotion/react */
import TimeAgo from 'react-timeago';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import koreanStrings from 'react-timeago/lib/language-strings/ko';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { css } from '@emotion/react';
import { useFragment } from 'relay-hooks';
import { COLORS, MQ } from '../core/constants/styles';
import { Card_post$key } from './__generated__/Card_post.graphql';
import { POST_TYPE } from '../core/constants/string';
import { Comment } from './Comment';
import { getLineBreakedSpan } from '../core/utils';

const formatter = buildFormatter(koreanStrings);

interface Props {
    post: Card_post$key;
}

export const Card: React.FunctionComponent<Props> = (props) => {
    const { post } = props;
    const data = useFragment(
        graphql`
            fragment Card_post on Post {
                id
                author
                type
                content
                createdAt
                ...Comment_post
            }
        `,
        post,
    );
    return (
        <div
            css={css`
                ${MQ({ width: ['100%', 270, 270, 270] })};
                background-color: ${COLORS.white};
                height: auto;
                border-radius: 15px;
                padding: 16px 20px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: flex-end;
                `}
            >
                <span
                    css={css`
                        font-weight: bold;
                        ${MQ({ fontSize: ['14px', '12px', '12px', '12px'] })}
                    `}
                >
                    {`${data.author}님의 ${POST_TYPE[data.type]}`}
                </span>
                <span
                    css={css`
                        ${MQ({ fontSize: ['12px', '8px', '8px', '8px'] })}
                        color: ${COLORS.grayText};
                        margin-left: 5px;
                    `}
                >
                    <TimeAgo date={data.createdAt as string} formatter={formatter} />
                </span>
            </div>
            <div
                css={css`
                    margin-top: 10px;
                    ${MQ({ fontSize: ['14px', '12px', '12px', '12px'] })}
                `}
            >
                {getLineBreakedSpan(data.content)}
            </div>
            <Comment post={data} />
        </div>
    );
};
