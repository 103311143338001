import facepaint from 'facepaint';

export const MEDIA_QUERY_BREAK_POINTS = [768, 1050, 1340];
export const MQ = facepaint(MEDIA_QUERY_BREAK_POINTS.map((e) => `@media (min-width: ${e}px)`));
export const MASONRY_BREAK_POINT_COLS = {
    766: 1,
    767: 2,
    1049: 3,
    1339: 4,
};

export const COLORS = {
    black: '#000000',
    white: '#ffffff',
    gray: '#f2f2f2',
    grayText: '#9b9b9b',
};
