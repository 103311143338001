/** @jsxImportSource @emotion/react */
import TextAreaAutosize from 'react-textarea-autosize';
import React from 'react';
import { css } from '@emotion/react';
import EditImage from '../images/edit.png';
import { COLORS } from '../core/constants/styles';

interface Props {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: (val: string) => void;
    disabled?: boolean;
}

export const CommentInput: React.FunctionComponent<Props> = (props: Props) => {
    const { value, setValue, onSubmit, disabled } = props;
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };
    const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(value);
    };
    return (
        <form
            css={css`
                background-color: ${COLORS.gray};
                width: 100%;
                border-radius: 15px;
                display: flex;
                align-items: center;
                position: relative;
            `}
            onSubmit={onSubmitForm}
        >
            <TextAreaAutosize
                disabled={disabled}
                css={css`
                    border: 0;
                    resize: none;
                    outline: none;
                    margin: 8px 12px;
                    flex: 1;
                    font-size: 12px;
                    background-color: ${COLORS.gray};
                    &::placeholder {
                        color: ${COLORS.grayText};
                    }
                `}
                placeholder="답글 달기"
                value={value}
                onChange={onChange}
            />
            <button
                disabled={disabled}
                type="submit"
                css={css`
                    cursor: pointer;
                    position: absolute;
                    right: 6px;
                    bottom: 4px;
                    display: ${value !== '' ? 'inline-block' : 'none'};
                `}
            >
                <img
                    css={css`
                        width: 20px;
                        height: 20px;
                        align-self: flex-end;
                    `}
                    src={EditImage}
                    alt="write"
                />
            </button>
        </form>
    );
};
