/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateCommentInput = {
    clientMutationId?: string | null;
    content: string;
    postId: string;
};
export type CommentCreateCommentMutationVariables = {
    input: CreateCommentInput;
};
export type CommentCreateCommentMutationResponse = {
    readonly createComment: {
        readonly commentEdge: {
            readonly node: {
                readonly id: string;
                readonly content: string;
            };
        };
    };
};
export type CommentCreateCommentMutation = {
    readonly response: CommentCreateCommentMutationResponse;
    readonly variables: CommentCreateCommentMutationVariables;
};



/*
mutation CommentCreateCommentMutation(
  $input: CreateCommentInput!
) {
  createComment(input: $input) {
    commentEdge {
      node {
        id
        content
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCommentPayload",
    "kind": "LinkedField",
    "name": "createComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreateCommentOutput",
        "kind": "LinkedField",
        "name": "commentEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Comment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentCreateCommentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommentCreateCommentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8fcc8263745e9fb86b594b2be5fee9b9",
    "id": null,
    "metadata": {},
    "name": "CommentCreateCommentMutation",
    "operationKind": "mutation",
    "text": "mutation CommentCreateCommentMutation(\n  $input: CreateCommentInput!\n) {\n  createComment(input: $input) {\n    commentEdge {\n      node {\n        id\n        content\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e2f010c9cf23c9b6fbd973bf1e41b9c0';
export default node;
