/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
import { css } from '@emotion/react';
import React from 'react';
import { COLORS } from '../core/constants/styles';

interface Props {
    disabled?: boolean;
    value: any;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    options: { title: string; value: any }[];
}

export const GraySelectBox: React.FunctionComponent<Props> = (props) => {
    const { options, value, setValue, disabled } = props;
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value);
    };
    return (
        <div
            css={css`
                width: 180px;
                height: 100%;
                background: ${COLORS.gray};
                border-radius: 8px;
                display: flex;
                align-items: center;
                position: relative;
            `}
        >
            <select
                disabled={disabled}
                value={value}
                onChange={onChange}
                css={css`
                    height: 100%;
                    appearance: none;
                    border: 0;
                    background: transparent;
                    font-size: 12px;
                    padding-left: 10px;
                    padding-right: 10px;
                    color: ${value === 'NONE' ? COLORS.grayText : undefined};
                `}
            >
                {options.map((each) => (
                    <option
                        css={css`
                            color: ${COLORS.grayText};
                        `}
                        key={each.title}
                        value={each.value}
                    >
                        {each.title}
                    </option>
                ))}
            </select>
            <img
                css={css`
                    position: absolute;
                    right: 10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                `}
                alt="arrow"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABiSURBVHgBjZDRDYAwCESF+N8VHIRdjBPoBo5g3KCzEJgLsYlG0zbtfZAD7oUEEJHNzNahQwBwjl6C+6kTCHAbZo6IODfykYgWeLoGlMLpyndagd5wBhSgX7gq/9yhqntpdwFZFiCz0RH+NgAAAABJRU5ErkJggg=="
            />
        </div>
    );
};
