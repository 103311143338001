import { useCallback, useEffect, useState } from 'react';
import { MEDIA_QUERY_BREAK_POINTS } from '../core/constants/styles';

const getWindowWidth = () => {
    if (typeof window === 'undefined') return null;
    return window.innerWidth;
};

/**
 * return width, isMobile
 */
export const useWindowWidth = (): [number, boolean] | [null, null] => {
    const [width, setWidth] = useState(getWindowWidth());
    const hasWindow = typeof window !== 'undefined';

    const handleResize = useCallback(() => {
        setWidth(getWindowWidth());
    }, [setWidth]);

    useEffect(() => {
        if (hasWindow) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow, handleResize]);
    if (width === null) {
        return [null, null];
    }
    return [width, width <= MEDIA_QUERY_BREAK_POINTS[0]];
};
