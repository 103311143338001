import React from 'react';

export function getLineBreakedSpan(val: string): React.ReactNode {
    return val.split('\n').map((line, idx) => {
        return (
            <span key={`${line}:${idx.toString()}`}>
                {line}
                <br />
            </span>
        );
    });
}
