import ReactGA from 'react-ga';

if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ReactGA.initialize('G-T2W3DTJDQD', { debug: process.env.NODE_ENV !== 'production' });
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export function sendEvent(arg: ReactGA.EventArgs): void {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event(arg);
    }
}
