import { RelayNetworkLayer, urlMiddleware } from 'react-relay-network-modern';
import { Store, RecordSource, Environment } from 'relay-runtime';

const source = new RecordSource();
const store = new Store(source);
let environment: Environment | undefined;

function createNetwork(): RelayNetworkLayer {
    return new RelayNetworkLayer([
        urlMiddleware({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            url: `${process.env.REACT_APP_SERVER_ENDPOINT!}/graphql`,
            headers: () => {
                const token = localStorage.getItem('token');
                return {
                    authorization: `Bearer ${token || 'null'}`,
                };
            },
        }),
    ]);
}

export function createEnvironment(): Environment {
    if (environment) {
        return environment;
    }
    environment = new Environment({
        network: createNetwork(),
        store,
    });
    return environment;
}
