/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AppRouterLoginKjhQueryVariables = {};
export type AppRouterLoginKjhQueryResponse = {
    readonly loginKjh: boolean;
};
export type AppRouterLoginKjhQuery = {
    readonly response: AppRouterLoginKjhQueryResponse;
    readonly variables: AppRouterLoginKjhQueryVariables;
};



/*
query AppRouterLoginKjhQuery {
  loginKjh
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "loginKjh",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppRouterLoginKjhQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppRouterLoginKjhQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "47c7139123cdcb3d13b2b73a5f668151",
    "id": null,
    "metadata": {},
    "name": "AppRouterLoginKjhQuery",
    "operationKind": "query",
    "text": "query AppRouterLoginKjhQuery {\n  loginKjh\n}\n"
  }
};
})();
(node as any).hash = '3091c5aa9d44d94324a9ab67914a3126';
export default node;
