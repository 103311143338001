/** @jsxImportSource @emotion/react */
import ClipLoader from 'react-spinners/ClipLoader';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useMutation } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import { ROOT_ID } from 'relay-runtime';
import pencilImage from '../images/pencil.png';
import { COLORS, MQ } from '../core/constants/styles';
import { GrayTextInput } from './GrayTextInput';
import { GraySelectBox } from './GraySelectBox';
import { InputBoxCreatePostMutation, PostType } from './__generated__/InputBoxCreatePostMutation.graphql';
import { IS_KJH_MODE } from '../core/constants/string';
import { sendEvent } from '../analytics';

export const InputBox: React.FunctionComponent = () => {
    const [name, setName] = useState('');
    const [type, setType] = useState<'NONE' | PostType>('NONE');
    const [text, setText] = useState('');

    const [mutateCreatePost, { loading }] = useMutation<InputBoxCreatePostMutation>(graphql`
        mutation InputBoxCreatePostMutation($input: CreatePostInput!) {
            createPost(input: $input) {
                postEdge {
                    node {
                        id
                        ...Card_post
                    }
                }
            }
        }
    `);

    const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    const onSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const trimmedName = name.trim();
        const trimmedText = text.trim();
        if (trimmedName.length === 0) {
            sendEvent({
                category: 'Submit',
                action: 'Fail',
                label: 'name',
            });
            alert('닉네임을 입력해주세요.');
            return;
        }
        if (trimmedText.length < 10) {
            sendEvent({
                category: 'Submit',
                action: 'Fail',
                label: 'text',
            });
            alert('최소 10자 이상 작성해주세요.');
            return;
        }
        if (type === 'NONE') {
            sendEvent({
                category: 'Submit',
                action: 'Fail',
                label: 'type',
            });
            alert('진호님께 무엇을 보낼지 선택해주세요.');
            return;
        }
        void mutateCreatePost({
            variables: {
                input: {
                    author: name,
                    content: text,
                    type,
                },
            },
            configs: [
                {
                    type: 'RANGE_ADD',
                    parentID: ROOT_ID,
                    connectionInfo: [
                        {
                            key: 'CardListPaginated_query_posts',
                            rangeBehavior: 'prepend',
                        },
                    ],
                    edgeName: 'postEdge',
                },
            ],
            onCompleted: () => {
                sendEvent({
                    category: 'Submit',
                    action: 'Success',
                });
                setName('');
                setText('');
                setType('NONE');
            },
            onError: (err) => {
                console.error(err);
            },
        });
    };

    if (IS_KJH_MODE) {
        return null;
    }

    return (
        <div
            css={css`
                position: relative;
                background-color: ${COLORS.white};
                border-radius: 15px;
                height: 280px;
                padding: 20px;
                flex-direction: column;
                ${MQ({ width: [0, 560, 560, 560], display: ['none', 'flex', 'flex', 'flex'] })}
            `}
        >
            <div
                css={css`
                    display: flex;
                    height: 34px;
                    width: 368px;
                    justify-content: space-between;
                    margin-bottom: 18px;
                `}
            >
                <GrayTextInput
                    disabled={loading}
                    maxLength={11}
                    value={name}
                    setValue={setName}
                    placeholder="당신은 누구인가요?"
                />
                <GraySelectBox
                    disabled={loading}
                    value={type}
                    setValue={setType}
                    options={[
                        {
                            title: '무엇을 보내고 싶으신가요?',
                            value: 'NONE',
                        },
                        {
                            title: '김진호에게 따뜻한 응원을',
                            value: 'CHEER',
                        },
                        {
                            title: '김진호와 함께한 추억을',
                            value: 'MEMORY',
                        },
                        {
                            title: '김진호에게 뜨거운 감사를',
                            value: 'THANKS',
                        },
                    ]}
                />
            </div>
            <textarea
                disabled={loading}
                css={css`
                    border: 0;
                    resize: none;
                    flex: 1;
                    font-size: 12px;
                    outline: none;
                    ::placeholder {
                        color: ${COLORS.grayText};
                    }
                `}
                value={text}
                onChange={onChangeText}
                placeholder="어떤 말을 전할까요?"
            />
            <div
                css={css`
                    text-align: right;
                    margin-top: 20px;
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <button
                    disabled={loading}
                    type="submit"
                    css={css`
                        cursor: pointer;
                        background-color: #5e85a0;
                        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        width: 101px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        color: ${COLORS.white};
                    `}
                    onClick={onSubmit}
                >
                    {loading ? <ClipLoader loading={loading} size={14} color={COLORS.white} /> : '정성스레 보내기'}
                </button>
            </div>
            <img src={pencilImage} alt="pencil" style={{ position: 'absolute', top: '-50px', right: '-30px' }} />
        </div>
    );
};
