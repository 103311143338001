/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { COLORS, MQ } from '../core/constants/styles';

export const TopText: React.FunctionComponent = () => {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                ${MQ({ marginBottom: [25, 38, 38, 38], flex: [1, 1, 1, 1] })}
            `}
        >
            <span
                css={css`
                    font-weight: 700;
                    color: ${COLORS.white};
                    ${MQ({ marginBottom: [12, 27, 27, 27], fontSize: [30, 50, 50, 50] })}
                `}
            >
                이제 우리가,{' '}
                <br
                    css={css`
                        ${MQ({ display: [undefined, 'none', 'none', 'none'] })}
                    `}
                />
                김진호에게
            </span>
            <span
                css={css`
                    color: ${COLORS.white};
                    ${MQ({ fontSize: [14, 16, 16, 16] })}
                `}
            >
                진호님 당신과 함께한 기억과, 감사를 소중히 적어 보냅니다.
                <br />
                작은 글들이 모여 당신에게 큰 의미가 되기를 바랍니다.
            </span>
        </div>
    );
};
