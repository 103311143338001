import { PostType } from '../../components/__generated__/InputBoxCreatePostMutation.graphql';

export const POST_TYPE: { [key in PostType]: string } = {
    CHEER: '응원',
    THANKS: '감사',
    MEMORY: '기억',
    '%future added value': '',
};

export const IS_KJH_MODE = window.location.href.includes('xn--o79at0flseb7vvma71pn9t.com');
// export const IS_KJH_MODE = true;
