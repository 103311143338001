import React, { useState } from 'react';
import { RelayEnvironmentProvider } from 'relay-hooks';
import { createEnvironment } from '../graphql/environment';
import { AppRouter } from './AppRouter';

export const App: React.FunctionComponent = () => {
    const [environment] = useState(createEnvironment());
    return (
        <RelayEnvironmentProvider environment={environment}>
            <AppRouter />
        </RelayEnvironmentProvider>
    );
};
