import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'relay-hooks';
import { BottomPostListQuery } from './__generated__/BottomPostListQuery.graphql';
import { CardList } from './CardList';
import { AddButton } from './AddButton';

export const Bottom: React.FunctionComponent = () => {
    const { data: query } = useQuery<BottomPostListQuery>(
        graphql`
            query BottomPostListQuery($first: Int!) {
                ...CardListPaginated_query @arguments(first: $first)
            }
        `,
        {
            first: 40,
        },
    );
    if (!query) {
        return null;
    }
    return (
        <>
            <CardList query={query} />
            <AddButton />
        </>
    );
};
