/** @jsxImportSource @emotion/react */
import ClipLoader from 'react-spinners/ClipLoader';
import graphql from 'babel-plugin-relay/macro';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useQuery } from 'relay-hooks';
import { IndexContainer } from './IndexContainer';
import { Bottom } from './Bottom';
import { Top } from './Top';
import { IndexInnerContainer } from './IndexInnerContainer';
import { COLORS, MQ } from '../core/constants/styles';
import { AppRouterLoginKjhQuery } from './__generated__/AppRouterLoginKjhQuery.graphql';
import { IS_KJH_MODE } from '../core/constants/string';
import { sendEvent } from '../analytics';

export const AppRouter: React.FunctionComponent = () => {
    const [code, setCode] = useState('');
    const [pass, setPass] = useState(false);
    const { data, error, isLoading, retry } = useQuery<AppRouterLoginKjhQuery>(
        graphql`
            query AppRouterLoginKjhQuery {
                loginKjh
            }
        `,
        {},
        {
            skip: !localStorage.getItem('token'),
        },
    );
    useEffect(() => {
        if (error) {
            alert('잘못된 인증코드를 입력하셨습니다. 다시 입력해주세요.');
            setCode('');
            sendEvent({
                category: 'Jinho Login',
                action: 'Login fail',
            });
        }
    }, [error]);
    useEffect(() => {
        if (data) {
            setCode('');
            setPass(true);
            sendEvent({
                category: 'Jinho Login',
                action: 'Login success',
            });
        }
    }, [data]);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if (val.length <= 10) {
            setCode(val);
        }
        if (val.length === 10) {
            localStorage.setItem('token', val);
            sendEvent({
                category: 'Jinho Login',
                action: 'Login try',
            });
            retry();
        }
    };
    if (IS_KJH_MODE && !pass) {
        return (
            <div
                css={css`
                    position: fixed;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: #343434;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                `}
            >
                <span
                    css={css`
                        color: ${COLORS.white};
                        font-weight: 700;
                        text-align: center;
                        ${MQ({ marginBottom: [20, 33, 33, 33], fontSize: [20, 36, 36, 36] })}
                    `}
                >
                    안녕하세요 김진호님!
                    <br />
                    인증 코드를 입력해주세요.
                </span>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        margin-top: 11px;
                        background-color: white;
                        border-radius: 8px;
                        width: 70%;
                        max-width: 300px;
                        height: 34px;
                        padding-left: 10px;
                        padding-right: 10px;
                        position: relative;
                    `}
                >
                    <input
                        disabled={isLoading}
                        maxLength={10}
                        type="password"
                        css={css`
                            font-size: 12px;
                            &::placeholder {
                                color: ${COLORS.grayText};
                            }
                        `}
                        placeholder="입력하기"
                        value={code}
                        onChange={onChange}
                    />
                    <div
                        css={css`
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <ClipLoader color={COLORS.white} loading={isLoading} />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <IndexContainer>
            <IndexInnerContainer>
                <Top />
                <Bottom />
            </IndexInnerContainer>
        </IndexContainer>
    );
};
