/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostType = "CHEER" | "MEMORY" | "THANKS" | "%future added value";
export type CreatePostInput = {
    author: string;
    clientMutationId?: string | null;
    content: string;
    type: PostType;
};
export type InputBoxCreatePostMutationVariables = {
    input: CreatePostInput;
};
export type InputBoxCreatePostMutationResponse = {
    readonly createPost: {
        readonly postEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"Card_post">;
            };
        };
    };
};
export type InputBoxCreatePostMutation = {
    readonly response: InputBoxCreatePostMutationResponse;
    readonly variables: InputBoxCreatePostMutationVariables;
};



/*
mutation InputBoxCreatePostMutation(
  $input: CreatePostInput!
) {
  createPost(input: $input) {
    postEdge {
      node {
        id
        ...Card_post
      }
    }
  }
}

fragment Card_post on Post {
  id
  author
  type
  content
  createdAt
  ...Comment_post
}

fragment Comment_post on Post {
  id
  checkedAt
  comment {
    id
    content
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InputBoxCreatePostMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePostPayload",
        "kind": "LinkedField",
        "name": "createPost",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatePostOutput",
            "kind": "LinkedField",
            "name": "postEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Post",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Card_post"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InputBoxCreatePostMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePostPayload",
        "kind": "LinkedField",
        "name": "createPost",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatePostOutput",
            "kind": "LinkedField",
            "name": "postEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Post",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "author",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checkedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2763efbade8feee2014e145f4e932bb8",
    "id": null,
    "metadata": {},
    "name": "InputBoxCreatePostMutation",
    "operationKind": "mutation",
    "text": "mutation InputBoxCreatePostMutation(\n  $input: CreatePostInput!\n) {\n  createPost(input: $input) {\n    postEdge {\n      node {\n        id\n        ...Card_post\n      }\n    }\n  }\n}\n\nfragment Card_post on Post {\n  id\n  author\n  type\n  content\n  createdAt\n  ...Comment_post\n}\n\nfragment Comment_post on Post {\n  id\n  checkedAt\n  comment {\n    id\n    content\n  }\n}\n"
  }
};
})();
(node as any).hash = '1a2badbbe041a2ee06246cd97b6be98b';
export default node;
