import React from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import graphql from 'babel-plugin-relay/macro';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { usePagination } from 'relay-hooks';
import { MASONRY_BREAK_POINT_COLS } from '../core/constants/styles';
import { useWindowWidth } from '../hooks/use-window-width';
import { Card } from './Card';
import { CardListPaginated_query$key } from './__generated__/CardListPaginated_query.graphql';

interface Props {
    query: CardListPaginated_query$key;
}

export const CardList: React.FunctionComponent<Props> = (props) => {
    const { query } = props;
    const [_, isMobile] = useWindowWidth();
    const { data, loadNext, hasNext } = usePagination(
        graphql`
            fragment CardListPaginated_query on Query
            @argumentDefinitions(first: { type: "Int", defaultValue: 40 }, after: { type: "String" })
            @refetchable(queryName: "PostRefetchQuery") {
                posts(first: $first, after: $after) @connection(key: "CardListPaginated_query_posts") {
                    edges {
                        node {
                            id
                            ...Card_post
                        }
                    }
                }
            }
        `,
        query,
    );
    const onScrollEnd = () => {
        if (hasNext) {
            loadNext(40);
        }
    };
    useBottomScrollListener(onScrollEnd, {
        offset: 300,
        triggerOnNoScroll: true,
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!data) {
        return null;
    }
    return (
        <ResponsiveMasonry style={{ paddingBottom: 20 }} columnsCountBreakPoints={MASONRY_BREAK_POINT_COLS}>
            <Masonry gutter={isMobile === true ? '13px' : '20px'}>
                {data.posts?.edges?.map((each) => {
                    if (!each?.node) return null;
                    return <Card key={each.node.id} post={each.node} />;
                })}
            </Masonry>
        </ResponsiveMasonry>
    );
};
