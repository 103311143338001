/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { COLORS } from '../core/constants/styles';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const GrayTextInput: React.FunctionComponent<Props> = (props) => {
    const { value, setValue, ...extraProps } = props;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };
    return (
        <div
            css={css`
                width: 180px;
                height: 100%;
                background: ${COLORS.gray};
                border-radius: 8px;
                display: flex;
                align-items: center;
                padding-left: 10px;
            `}
        >
            <input
                {...extraProps}
                css={css`
                    font-size: 12px;
                    &::placeholder {
                        color: ${COLORS.grayText};
                    }
                `}
                onChange={onChange}
                value={value}
                type="text"
            />
        </div>
    );
};
