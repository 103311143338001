/** @jsxImportSource @emotion/react */
import graphql from 'babel-plugin-relay/macro';
import ClipLoader from 'react-spinners/ClipLoader';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { ROOT_ID } from 'relay-runtime';
import { useMutation } from 'relay-hooks';
import BackImage from '../images/back.png';
import { COLORS } from '../core/constants/styles';
import { PostType } from './__generated__/InputBoxCreatePostMutation.graphql';
import { MobileInputCreatePostMutation } from './__generated__/MobileInputCreatePostMutation.graphql';
import { sendEvent } from '../analytics';

type Step = 'name' | 'type' | 'text';

interface Props {
    onClickBack: () => void;
}

export const MobileInput: React.FunctionComponent<Props> = (props) => {
    const { onClickBack } = props;
    const [mutateCreatePost, { loading }] = useMutation<MobileInputCreatePostMutation>(graphql`
        mutation MobileInputCreatePostMutation($input: CreatePostInput!) {
            createPost(input: $input) {
                postEdge {
                    node {
                        id
                        ...Card_post
                    }
                }
            }
        }
    `);
    const [step, setStep] = useState<Step>('name');
    const [name, setName] = useState('');
    const [type, setType] = useState<PostType | undefined>(undefined);
    const [text, setText] = useState('');
    const onClickBackButton = () => {
        sendEvent({
            category: 'MobileInput',
            action: 'Press Back',
            label: step,
        });
        if (step === 'text') {
            setStep('type');
        } else if (step === 'type') {
            setStep('name');
        } else {
            onClickBack();
        }
    };
    const onChangeNameText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setType(e.target.value as PostType);
    };

    const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    const onSubmit = () => {
        const trimmedName = name.trim();
        const trimmedText = text.trim();
        if (trimmedName.length === 0) {
            sendEvent({
                category: 'Submit',
                action: 'Fail',
                label: 'name',
            });
            alert('닉네임을 입력해주세요.');
            return;
        }
        if (trimmedText.length < 10) {
            sendEvent({
                category: 'Submit',
                action: 'Fail',
                label: 'text',
            });
            alert('최소 10자 이상 작성해주세요');
            return;
        }
        if (type === undefined) {
            sendEvent({
                category: 'Submit',
                action: 'Fail',
                label: 'type',
            });
            alert('진호님께 무엇을 보낼지 선택해주세요.');
            return;
        }
        void mutateCreatePost({
            variables: {
                input: {
                    author: name,
                    content: text,
                    type,
                },
            },
            configs: [
                {
                    type: 'RANGE_ADD',
                    parentID: ROOT_ID,
                    connectionInfo: [
                        {
                            key: 'CardListPaginated_query_posts',
                            rangeBehavior: 'prepend',
                        },
                    ],
                    edgeName: 'postEdge',
                },
            ],
            onCompleted: () => {
                setName('');
                setText('');
                setType(undefined);
                setStep('name');
                sendEvent({
                    category: 'Submit',
                    action: 'Success',
                });
                onClickBack();
            },
            onError: (err) => {
                console.error(err);
            },
        });
    };
    const onClickNextStep = () => {
        if (step === 'name') {
            sendEvent({
                category: 'MobileInput',
                action: 'Press Next',
                label: 'name',
            });
            if (name.trim()) {
                setStep('type');
            } else {
                sendEvent({
                    category: 'Submit',
                    action: 'Fail',
                    label: 'name',
                });
                alert('닉네임을 입력해주세요.');
            }
        } else if (step === 'type') {
            sendEvent({
                category: 'MobileInput',
                action: 'Press Next',
                label: 'type',
            });
            if (type) {
                setStep('text');
            } else {
                sendEvent({
                    category: 'Submit',
                    action: 'Fail',
                    label: 'type',
                });
                alert('진호님께 무엇을 보낼지 선택해주세요.');
            }
        } else {
            sendEvent({
                category: 'MobileInput',
                action: 'Press Next',
                label: 'text',
            });
            onSubmit();
        }
    };

    return (
        <form
            css={css`
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #343434;
                display: flex;
                flex-direction: column;
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    color: ${COLORS.white};
                    font-size: 16px;
                    font-weight: 700;
                    height: 52px;
                `}
            >
                <button
                    css={css`
                        background-color: #343434;
                        padding-left: 20px;
                        padding-right: 6px;
                        color: ${COLORS.white};
                        font-size: 16px;
                        font-weight: 700;
                    `}
                    type="button"
                    onClick={onClickBackButton}
                >
                    <img
                        src={BackImage}
                        css={css`
                            max-width: 24px;
                            width: auto;
                            height: auto;
                            margin-top: 6px;
                        `}
                        alt="back"
                    />
                </button>
                김진호에게 편지 적기
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    flex: 1;
                `}
            >
                {/* eslint-disable-next-line no-nested-ternary */}
                {step === 'name' ? (
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                        `}
                    >
                        <span
                            css={css`
                                font-weight: 700;
                                font-size: 18px;
                                color: ${COLORS.white};
                            `}
                        >
                            당신은 누구인가요?
                        </span>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                margin-top: 11px;
                                background-color: white;
                                border-radius: 8px;
                                width: 70%;
                                height: 34px;
                                padding-left: 10px;
                                padding-right: 10px;
                            `}
                        >
                            <input
                                type="text"
                                css={css`
                                    font-size: 12px;
                                    &::placeholder {
                                        color: ${COLORS.grayText};
                                    }
                                `}
                                placeholder="입력하기"
                                maxLength={11}
                                value={name}
                                onChange={onChangeNameText}
                            />
                        </div>
                    </div>
                ) : step === 'type' ? (
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                        `}
                    >
                        <span
                            css={css`
                                font-weight: 700;
                                font-size: 18px;
                                color: ${COLORS.white};
                                margin-bottom: 19px;
                            `}
                        >
                            무엇을 보내고 싶으신가요?
                        </span>
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding-left: calc(50% - 88px);
                                font-size: 14px;
                                color: ${COLORS.white};
                            `}
                        >
                            <label
                                htmlFor="CHEER"
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <input
                                    css={css`
                                        margin-right: 7px;
                                    `}
                                    onChange={onChangeType}
                                    id="CHEER"
                                    type="radio"
                                    name="TYPE"
                                    value="CHEER"
                                    checked={type === 'CHEER'}
                                />
                                김진호에게 따뜻한 응원을
                            </label>
                            <label
                                htmlFor="MEMORY"
                                css={css`
                                    margin-top: 10px;
                                    margin-bottom: 10px;
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <input
                                    css={css`
                                        margin-right: 7px;
                                    `}
                                    onChange={onChangeType}
                                    id="MEMORY"
                                    type="radio"
                                    name="TYPE"
                                    value="MEMORY"
                                    checked={type === 'MEMORY'}
                                />
                                김진호와 함께한 추억을
                            </label>
                            <label
                                htmlFor="THANKS"
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <input
                                    css={css`
                                        margin-right: 7px;
                                    `}
                                    onChange={onChangeType}
                                    id="THANKS"
                                    type="radio"
                                    name="TYPE"
                                    value="THANKS"
                                    checked={type === 'THANKS'}
                                />
                                김진호에게 뜨거운 감사를
                            </label>
                        </div>
                    </div>
                ) : (
                    <textarea
                        css={css`
                            width: 100%;
                            padding-left: 26px;
                            padding-right: 26px;
                            background-color: #343434;
                            border: 0;
                            resize: none;
                            flex: 1;
                            color: ${COLORS.white};
                            font-size: 18px;
                            outline: none;
                            ::placeholder {
                                color: ${COLORS.grayText};
                            }
                        `}
                        value={text}
                        onChange={onChangeText}
                        placeholder="어떤 말을 전할까요?"
                    />
                )}
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 23px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                    `}
                >
                    <div
                        css={css`
                            height: 4px;
                            border-radius: 2px;
                            width: 30px;
                            background-color: #515151;
                        `}
                    />
                    <div
                        css={css`
                            height: 4px;
                            border-radius: 2px;
                            width: 30px;
                            background-color: ${step === 'name' ? COLORS.white : '#515151'};
                            margin-left: 3px;
                            margin-right: 3px;
                        `}
                    />
                    <div
                        css={css`
                            height: 4px;
                            border-radius: 2px;
                            width: 30px;
                            background-color: ${step === 'text' ? '#515151' : COLORS.white};
                        `}
                    />
                </div>
                <button
                    onClick={onClickNextStep}
                    type="button"
                    css={css`
                        margin-top: 15px;
                        background-color: #515151;
                        width: 241px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: ${COLORS.white};
                        border-radius: 20px;
                        font-size: 14px;
                        font-weight: 700;
                    `}
                >
                    {loading ? <ClipLoader loading={loading} size={14} color={COLORS.white} /> : '다음'}
                </button>
            </div>
        </form>
    );
};
