/** @jsxImportSource @emotion/react */
import graphql from 'babel-plugin-relay/macro';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useFragment, useMutation } from 'relay-hooks';
import kjhImage from '../images/kjh.png';
import { Comment_post$key } from './__generated__/Comment_post.graphql';
import { COLORS, MQ } from '../core/constants/styles';
import { IS_KJH_MODE } from '../core/constants/string';
import { CommentInput } from './CommentInput';
import { CommentCreateCommentMutation } from './__generated__/CommentCreateCommentMutation.graphql';
import { getLineBreakedSpan } from '../core/utils';

interface Props {
    post: Comment_post$key;
}

export const Comment: React.FunctionComponent<Props> = (props) => {
    const { post } = props;
    const [value, setValue] = useState('');
    const data = useFragment(
        graphql`
            fragment Comment_post on Post {
                id
                checkedAt
                comment {
                    id
                    content
                }
            }
        `,
        post,
    );
    const [createComment, { loading }] = useMutation<CommentCreateCommentMutation>(
        graphql`
            mutation CommentCreateCommentMutation($input: CreateCommentInput!) {
                createComment(input: $input) {
                    commentEdge {
                        node {
                            id
                            content
                        }
                    }
                }
            }
        `,
    );
    const onSubmitComment = () => {
        void createComment({
            variables: {
                input: {
                    content: value,
                    postId: data.id,
                },
            },
            updater: (store, d) => {
                const postRecord = store.get(data.id);
                const commentRecord = store.get(d.createComment.commentEdge.node.id);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                postRecord?.setLinkedRecord(commentRecord!, 'comment');
            },
            onError: (err) => {
                console.error(err);
            },
        });
    };
    if (!data.checkedAt && !IS_KJH_MODE) {
        return null;
    }
    return (
        <div
            css={css`
                display: flex;
                align-items: ${data.comment ? 'start' : 'center'};
                margin-top: 10px;
            `}
        >
            {IS_KJH_MODE ? null : (
                <img
                    src={kjhImage}
                    alt="kjh"
                    css={css`
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                        margin-right: 5px;
                    `}
                />
            )}
            {/* eslint-disable-next-line no-nested-ternary */}
            {data.comment ? (
                <div
                    css={css`
                        background-color: #f2f2f2;
                        border-radius: 15px;
                        flex: 1;
                        padding: 8px 12px;
                        ${MQ({ fontSize: ['14px', '12px', '12px', '12px'] })}
                    `}
                >
                    {getLineBreakedSpan(data.comment.content)}
                </div>
            ) : IS_KJH_MODE ? (
                <CommentInput disabled={loading} onSubmit={onSubmitComment} value={value} setValue={setValue} />
            ) : (
                <span
                    css={css`
                        ${MQ({ fontSize: ['14px', '12px', '12px', '12px'] })};
                        color: ${COLORS.grayText};
                    `}
                >
                    진호님이 소중히 읽었어요
                </span>
            )}
        </div>
    );
};
