/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { MQ } from '../core/constants/styles';
import { MobileInput } from './MobileInput';
import WriteImage from '../images/write.png';
import { sendEvent } from '../analytics';

export const AddButton: React.FunctionComponent = () => {
    const [open, setOpen] = useState(false);
    const onClickButton = () => {
        sendEvent({
            category: 'Mobile Add Button',
            action: 'Click',
        });
        setOpen(true);
    };
    const onCloseMobile = () => {
        setOpen(false);
    };
    return (
        <>
            <button
                type="button"
                onClick={onClickButton}
                css={css`
                    ${MQ({ display: ['flex', 'none', 'none', 'none'] })};
                    cursor: pointer;
                    right: 10px;
                    bottom: 10px;
                    position: fixed;
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    background: #5e85a0;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <img
                    css={css`
                        width: 30px;
                        height: 30px;
                    `}
                    src={WriteImage}
                    alt="write"
                />
            </button>
            {open ? <MobileInput onClickBack={onCloseMobile} /> : null}
        </>
    );
};
